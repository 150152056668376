import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
// import * as emailjs from 'emailjs-com';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import *  as content from '../content-site';

class VendaSeuVeiculo extends Component {

    constructor (props){
        super(props);

        this.state = {
            nome:'',
            email:'',
            telefone:'',
            marca:'',
            modelo:'',
            versao: '',
            cor: '',
            km: '',
            placa: '',
            ano_modelo: '',
            opcionais: '',
            enviado: false,
            msg_enviado: '',
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        event.preventDefault();
        
        this.setState({enviado:true, msg_enviado: 'Enviando mensagem...'});

        var mensagem = "Nome: "+ this.state.nome;
            mensagem += "<br>E-mail: "+ this.state.email;
            mensagem += "<br>Telefone: "+ this.state.telefone;
            mensagem += "<br><br>Marca: "+ this.state.marca;
            mensagem += "<br>Modelo: "+ this.state.modelo;
            mensagem += "<br>Versão: "+ this.state.versao;
            mensagem += "<br>Cor: "+ this.state.cor;
            mensagem += "<br>KM: "+ this.state.km;
            mensagem += "<br>Placa: "+ this.state.placa;
            mensagem += "<br>Ano/Modelo: "+ this.state.ano_modelo;
            mensagem += "<br>Opcionais: "+ this.state.opcionais;

        fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: "Contato " + content.nome_revenda + "<" + content.email + ">",
                assunto: "Venda seu veículo - Site " + content.nome_revenda,
                destinatarios: content.email,
                corpo: mensagem,
                corpoHtml: mensagem
            })
        })
        .then(data => {
            this.setState({
                enviado: true, 
                msg_enviado: 'E-Mail enviado com sucesso, em breve você receberá um retorno da nossa equipe.',
                nome: "", 
                email: "", 
                telefone: "", 
                marca:'',
                modelo:'',
                versao: '',
                cor: '',
                km: '',
                placa: '',
                ano_modelo: '',
                opcionais: '',
            });
            setInterval(() => this.setState({enviado:false}), 5000);
        })
        .catch(error => {
            this.setState({enviado:true, msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde'});
            setInterval(() => this.setState({enviado:false}), 5000);
        });

    }

    render(){
        return(
            <div>
                <Cabecalho />
                <div>
                    {/* <section className="b-pageHeader">
                        <div className="container">
                            <h1 className="wow zoomInLeft" data-wow-delay="0.5s">Venda seu veículo</h1>
                        </div>
                    </section> */}
                    <section className="b-contacts s-shadow">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-6">
                                    <div className="b-contacts__form ">
                                        <header className="b-contacts__form-header s-lineDownLeft wow zoomInUp" data-wow-delay="0.5s">
                                            <h2 className="s-titleDet">Solicite uma avaliação do seu veículo</h2> 
                                        </header>
                                        <p className=" wow zoomInUp" data-wow-delay="0.5s">Envie para nossa equipe os dados do seu veículo para fazermos uma proposta. Em breve retornaremos o contato.</p>
                                        <ValidatorForm id="contactForm" className="s-form wow zoomInUp" ref="form" onSubmit={this.handleSubmit} >
                                            <TextValidator
                                                label="NOME *"
                                                onChange={this.handleChange}
                                                name="nome"
                                                value={this.state.nome}
                                                className="form-ui-bootstrap"
                                                validators={['required']}
                                                errorMessages={['Preenchimento obrigatório']}
                                            />
                                            <TextValidator
                                                label="E-MAIL *"
                                                onChange={this.handleChange}
                                                name="email"
                                                value={this.state.email}
                                                className="form-ui-bootstrap"
                                                validators={['required', 'isEmail']}
                                                errorMessages={['Preenchimento obrigatório', 'E-mail inválido']}
                                            />
                                            <InputMask
                                                mask="(99) 99999-9999"
                                                value={this.state.telefone}
                                                label="TELEFONE *"
                                                name="telefone"
                                                onChange={this.handleChange}
                                            >
                                                {() => <TextField
                                                label="TELEFONE *"
                                                name="telefone"
                                                type="text"
                                                className="form-ui-bootstrap"
                                                />}
                                            </InputMask> 
                                            <TextValidator
                                                label="MARCA *"
                                                onChange={this.handleChange}
                                                name="marca"
                                                value={this.state.marca}
                                                className="form-ui-bootstrap"
                                                validators={['required']}
                                                errorMessages={['Preenchimento obrigatório']}
                                            />
                                            <TextValidator
                                                label="MODELO *"
                                                onChange={this.handleChange}
                                                name="modelo"
                                                value={this.state.modelo}
                                                className="form-ui-bootstrap"
                                                validators={['required']}
                                                errorMessages={['Preenchimento obrigatório']}
                                            />
                                            <TextValidator
                                                label="VERSÃO"
                                                onChange={this.handleChange}
                                                name="versao"
                                                value={this.state.versao}
                                                className="form-ui-bootstrap"
                                            />
                                            <TextValidator
                                                label="COR"
                                                onChange={this.handleChange}
                                                name="cor"
                                                value={this.state.cor}
                                                className="form-ui-bootstrap"
                                            />
                                            <TextValidator
                                                label="KM *"
                                                onChange={this.handleChange}
                                                name="km"
                                                value={this.state.km}
                                                className="form-ui-bootstrap"
                                                validators={['required']}
                                                errorMessages={['Preenchimento obrigatório']}
                                            />
                                            <TextValidator
                                                label="PLACA"
                                                onChange={this.handleChange}
                                                name="placa"
                                                value={this.state.placa}
                                                className="form-ui-bootstrap"
                                            />
                                            <TextValidator
                                                label="ANO/MODELO *"
                                                onChange={this.handleChange}
                                                name="ano_modelo"
                                                value={this.state.ano_modelo}
                                                className="form-ui-bootstrap"
                                                validators={['required']}
                                                errorMessages={['Preenchimento obrigatório']}
                                            />
                                            <TextValidator
                                                label="OPCIONAIS"
                                                onChange={this.handleChange}
                                                name="opcionais"
                                                value={this.state.opcionais}
                                                className="form-ui-bootstrap"
                                            />
                                            <button type="submit" className="btn m-btn">ENVIAR DADOS<span className="fa fa-angle-right"></span></button>
                                            {
                                                this.state.enviado &&
                                                <blockquote>
                                                    <p><em>{ this.state.msg_enviado }</em></p>
                                                </blockquote>
                                            }
                                        </ValidatorForm>
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    <div className="b-contacts__address">
                                        <div className="b-contacts__address-hours">
                                            <h2 className="s-titleDet wow zoomInUp" data-wow-delay="0.5s">Atendimento</h2>
                                            <div className="b-contacts__address-hours-main wow zoomInUp" data-wow-delay="0.5s">
                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12">
                                                        <p>Seg-Sex : { content.horario_seg_sex } <br/>Sab : { content.horario_sab }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="b-contacts__address-info">
                                            <h2 className="s-titleDet wow zoomInUp" data-wow-delay="0.5s">Fale conosco</h2>
                                            <address className="b-contacts__address-info-main wow zoomInUp" data-wow-delay="0.5s">
                                                <div className="b-contacts__address-info-main-item">
                                                    <span className="fa fa-home"></span>
                                                    Endereço
                                                    <p>{ content.endereco }, { content.cidade }</p>
                                                </div>
                                                <div className="b-contacts__address-info-main-item">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-xs-12">
                                                            <span className="fa fa-phone"></span>
                                                            Telefone
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-xs-12">
                                                            <em><a href={ "tel:" + content.telefone_link } >{ content.telefone }</a></em>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="b-contacts__address-info-main-item">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-xs-12">
                                                            <span className="fa fa-whatsapp"></span>
                                                            Whatsapp
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-xs-12">
                                                            <em><a href={ content.whatsapp_link }>{ content.whatsapp }</a></em>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="b-contacts__address-info-main-item">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-xs-12">
                                                            <span className="fa fa-envelope"></span>
                                                            E-mail
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-xs-12">
                                                            <em><a href={ "mailto:" + content.email }>{ content.email }</a></em>
                                                        </div>
                                                    </div>
                                                </div>
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <Rodape />
            </div>
        )
    }
}

export default VendaSeuVeiculo