// alterar arquivo: public/index.html
// substituir imagens: public/img
// substituir favicon: /public/favicon.ico

// alterar arquivos: .firebaserc, firebase.json e package.json com o nome do projeto no firebase

// color primary: public/assets/switcher/css/color2.css
// color secondary: public/assets/switcher/css/color2.css: 135

export const nome_revenda = "Pirelli Veículos";
export const url_revenda  = "https://pirelliveiculos.com.br/";
export const id_firebase  = "oCGEmX9HG86PnWEutkJg";
export const texto_sobre_nos = "Veículos novos e seminovos com procedência e qualidade, nosso objetivo é alcançar a satisfação do cliente com veículos selecionados, bom atendimento e taxas de financiamento competitivas faça um visita a nossa loja e conheça nosso amplo estoque.";
export const horario_seg_sex = "07:00 - 18:00";
export const horario_sab = "07:00 - 12:00";
export const endereco = "Avenida Talhamares, 1260 - Jardim Marajoara";
export const cidade = "Cáceres - MT";
export const telefone = "(65) 99974-7204";
export const telefone_link = "65999747204";
export const whatsapp = "(65) 99974-7204";
export const whatsapp_link = "https://api.whatsapp.com/send?phone=5565999747204";
export const email = "contato@pirelliveiculos.com.br";
export const mapa_link = "https://www.google.com/maps/place/Pirelli+Ve%C3%ADculos+-+C%C3%A1ceres+-+MT/@-16.0898946,-57.6799965,15z/data=!4m2!3m1!1s0x0:0xe961d689a961ee62?sa=X&ved=2ahUKEwiv9rSO4PPuAhVnI7kGHfrmCCoQ_BIwEnoECCEQBQ";
export const instagram_link = "https://www.instagram.com/pirelliveiculos/";
export const facebook_link = "https://www.facebook.com/Pirelli-Ve%C3%ADculos-C%C3%A1ceres-MT-101912728294310/?";
