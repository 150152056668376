import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Inicio from './pages/Inicio';
import Contato from './pages/Contato';
import Veiculo from './pages/Veiculo';
import Financiamento from './pages/Financiamento';
import VendaSeuVeiculo from './pages/VendaSeuVeiculo';

class App extends Component {
  render(){
    return (    
      // <BrowserRouter>      
      //   <div className="App" id="TopoSite">      
      //     <Route path='/' exact component={Inicio} />                  
      //     <Route path='/contato' component={Contato} />                  
      //     <Route path='/financiamento' component={Financiamento} />                  
      //     <Route path='/venda-seu-veiculo' component={VendaSeuVeiculo} />                  
      //     <Route path='/veiculo/:idVeiculo' component={Veiculo} />
      //   </div>
      // </BrowserRouter>

      <Router>
        {/* <div className="App" id="TopoSite">     */}
          <Switch>  
              <Route path='/' exact component={Inicio} />                  
              <Route path='/veiculo/:idVeiculo' component={Veiculo} />
              <Route path='/contato' component={Contato} />                  
              <Route path='/financiamento' component={Financiamento} />                  
              <Route path='/venda-seu-veiculo' component={VendaSeuVeiculo} />                  
          </Switch> 
        {/* </div> */}
      </Router>
    );
  }
}

export default App;
