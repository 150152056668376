import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { firestore } from './../firebase-config';
import Slider from "react-slick";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import *  as content from '../content-site';
// import {Helmet} from 'react-helmet';
// import DocumentMeta from 'react-document-meta';

function ImagensVeiculo(props) {
    const imagens = props.imagens;

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        accessibility: true
    };

    if (imagens !== undefined && imagens.length > 0) {
        const listImagens = imagens.map((key) => 
            <div key={key.imagem}><img className="img-responsive center-block" src={ key.fileUrl } alt="Imagem veículo" /></div>
        );
        return <Slider {...settings}>{ listImagens }</Slider>;
    }else{
        return <div><img className="img-responsive center-block" src="/img/car_default.png" alt="nissan" /></div>;
    }

}

function ObservacoesVeiculo(props){

    const veobservacao = props.veobservacao;

    if (veobservacao !== undefined && veobservacao.length > 0) {

        return (
            <div className="b-detail__main-info-extra wow zoomInUp" data-wow-delay="0.5s">
                <div>
                    <h2 className="s-titleDet">INFORMAÇÕES EXTRAS</h2>
                    <p>{ veobservacao }</p>
                </div>
            </div>
        );
    }else{
        return <div></div>;
    }

}

function OpcionaisVeiculo(props){

    const opcionais = props.opcionais;

    if (opcionais !== undefined && opcionais.length > 0) {

        const listOpcionais = opcionais.map((key) => 
            <li key={key.id}><span className="fa fa-check"></span>{key.descricao}</li>
        );
        return <div className="b-detail__main-info-extra wow zoomInUp" data-wow-delay="0.5s"><h2 className="s-titleDet">OPCIONAIS</h2><ul>{ listOpcionais }</ul></div>;

    }else{
        return <div></div>;
    }

}

class Contato extends Component {

    constructor (props){
        super(props);

        this.state = {
            veiculo: {},
            idVeiculo: props.match.params.idVeiculo,
            nome:'',
            email: '',
            telefone: '',
            mensagem: '',
            enviado: false,
            msg_enviado: '',
            url_veiculo: window.location.href
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount(){

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(content.id_firebase).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    veiculo: doc.data(),
                });
            }
        }).catch(function(error) {
        });
        
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        event.preventDefault();
        
        this.setState({enviado:true, msg_enviado: 'Enviando mensagem...'});
        // var url_veiculo = window.location.href;
        var mensagem = "Nome: "+ this.state.nome + "<br>E-mail: "+ this.state.email + "<br>Telefone: "+ this.state.telefone + "<br>Veículo: "+ this.state.url_veiculo + "<br>Mensagem: "+ this.state.mensagem;

        fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente:  "Contato " + content.nome_revenda + "<" + content.email + ">",
                assunto: "Interesse no veículo - Site " + content.nome_revenda,
                destinatarios: content.email,
                corpo: mensagem,
                corpoHtml: mensagem
            })
        })
        .then(data => {
            this.setState({
                enviado: true, 
                msg_enviado: 'E-Mail Enviado com Sucesso',
                nome: "", 
                email: "", 
                telefone: "", 
                mensagem: "", 
            });
            setInterval(() => this.setState({enviado:false}), 5000);
        })
        .catch(error => {
            this.setState({enviado:true, msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde'});
            setInterval(() => this.setState({enviado:false}), 5000);
        });

    }

    render(){
        const { 
            veiculo,
            url_veiculo
        } = this.state;
        // const meta = {
        //     title: "Primocar Automóveis - " + veiculo.vefipe_marca + " " + veiculo.vefipe_name,
        //     meta: {
        //         property: {
        //             'og:title': "Primocar Automóveis - " + veiculo.vefipe_marca + " " + veiculo.vefipe_name,
        //             'og:url': url_veiculo,
        //             'og:image': veiculo.imagens !== undefined && veiculo.imagens.length > 0 ?
        //                 <meta property="og:image" content={ veiculo.imagens[0].fileUrl } />
        //             : "",
        //             'og:type': "website",
        //             'og:description': "Primocar Automóveis - Os melhores veículos de Tupaciguara-MG e região",
        //         },
        //         name: {
        //             'description': "Primocar Automóveis - Os melhores veículos de Tupaciguara-MG e região"
        //         }
        //     }
        // };
        return(
            <div>
                {/* <DocumentMeta {...meta} /> */}
                {/* <Helmet>
                    <title>{ "Primocar Automóveis - " + veiculo.vefipe_marca + " " + veiculo.vefipe_name }</title>
                    <meta name="description" content="Primocar Automóveis - Os melhores veículos de Tupaciguara-MG e região" />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content="Primocar Automóveis - Os melhores veículos de Tupaciguara-MG e região" />
                    <meta property="og:url" content={ url_veiculo } />
                    <meta property="og:title" content={ "Primocar Automóveis - " + veiculo.vefipe_marca + " " + veiculo.vefipe_name } />
                    {
                        veiculo.imagens !== undefined && veiculo.imagens.length > 0 ?
                            <meta property="og:image" content={ veiculo.imagens[0].fileUrl } />
                        : ""
                    }
                </Helmet> */}
                <Cabecalho />
                <div>
                    
                    {/* <section className="b-pageHeader">
                        <div className="container">
                            <h1 className="wow zoomInLeft" data-wow-delay="0.5s">Detalhes do veículo</h1>
                        </div>
                    </section> */}

                    <section className="b-detail s-shadow">
                        <div className="container">
                            <header className="b-detail__head s-lineDownLeft wow zoomInUp" data-wow-delay="0.5s">
                                <div className="row">
                                    <div className="col-sm-8 col-xs-12">
                                        <div className="b-detail__head-title">
                                            <h1>{ veiculo.vefipe_marca } { veiculo.vefipe_name }</h1>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <div className="b-detail__head-price">
                                            <div className="b-detail__head-price-num">{ formatMoney(veiculo.vevalor) }</div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="b-detail__main">
                                <div className="row">
                                    <div className="col-md-8 col-xs-12">
                                        <div className="b-detail__main-info">
                                            <div className="b-detail__main-info-images wow zoomInUp" data-wow-delay="0.5s">
                                                <div className="row m-smallPadding">
                                                    <div className="col-xs-12">
                                                        <ImagensVeiculo imagens={ veiculo.imagens } />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <ObservacoesVeiculo veobservacao={ veiculo.veobservacao } />

                                            <OpcionaisVeiculo opcionais={ veiculo.opcionais } />
                                
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <aside className="b-detail__main-aside">
                                            <div className="b-detail__main-aside-desc wow zoomInUp" data-wow-delay="0.5s">
                                                <h2 className="s-titleDet">DESCRIÇÃO</h2>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <h4 className="b-detail__main-aside-desc-title">Marca</h4>
                                                    </div>
                                                    <div className="col-xs-8">
                                                        <p className="b-detail__main-aside-desc-value">{ veiculo.vefipe_marca }</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <h4 className="b-detail__main-aside-desc-title">Modelo</h4>
                                                    </div>
                                                    <div className="col-xs-8">
                                                        <p className="b-detail__main-aside-desc-value">{ veiculo.vefipe_name }</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <h4 className="b-detail__main-aside-desc-title">Km</h4>
                                                    </div>
                                                    <div className="col-xs-8">
                                                        <p className="b-detail__main-aside-desc-value">{ veiculo.vequilometragem } km</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <h4 className="b-detail__main-aside-desc-title">Fabricação</h4>
                                                    </div>
                                                    <div className="col-xs-8">
                                                        <p className="b-detail__main-aside-desc-value">{ veiculo.veano_fabricacao }</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <h4 className="b-detail__main-aside-desc-title">Modelo</h4>
                                                    </div>
                                                    <div className="col-xs-8">
                                                        <p className="b-detail__main-aside-desc-value">{ veiculo.vefipe_ano_modelo }</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <h4 className="b-detail__main-aside-desc-title">Combustível</h4>
                                                    </div>
                                                    <div className="col-xs-8">
                                                        <p className="b-detail__main-aside-desc-value">{ veiculo.vefipe_combustivel }</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <h4 className="b-detail__main-aside-desc-title">Câmbio</h4>
                                                    </div>
                                                    <div className="col-xs-8">
                                                        <p className="b-detail__main-aside-desc-value">{ formatCambio(veiculo.vecambio) }</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <h4 className="b-detail__main-aside-desc-title">Cor</h4>
                                                    </div>
                                                    <div className="col-xs-8">
                                                        <p className="b-detail__main-aside-desc-value">{ veiculo.vecor }</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <h4 className="b-detail__main-aside-desc-title">Portas</h4>
                                                    </div>
                                                    <div className="col-xs-8">
                                                        <p className="b-detail__main-aside-desc-value">{ veiculo.veportas }</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="b-detail__main-aside-about wow zoomInUp" data-wow-delay="0.5s">
                                                <h2 className="s-titleDet">TENHO INTERESSE NESTE VEÍCULO</h2>
                                                <div className="b-detail__main-aside-about-call">
                                                    <span className="fa fa-phone"></span>
                                                    <div><a href={ content.whatsapp_link }>{ content.whatsapp }</a></div>
                                                    <p>Entre em contato pelo Whatsapp ou ligação</p>
                                                </div>
                                                <div className="b-detail__main-aside-about-seller">
                                                    <p>Telefone: <span><a href={ "tel:" + content.telefone_link }>{ content.telefone }</a></span></p>
                                                </div>
                                                <div className="b-detail__main-aside-about-form">
                                                    <div className="b-detail__main-aside-about-form-links">
                                                        <a href="#" className="j-tab m-active s-lineDownCenter" data-to='#form1'>DEIXE SEUS DADOS</a>
                                                    </div>
                                                    <ValidatorForm id="form-contato" ref="form" onSubmit={this.handleSubmit} >
                                                        <TextValidator
                                                            label="NOME *"
                                                            onChange={this.handleChange}
                                                            name="nome"
                                                            value={this.state.nome}
                                                            className="form-ui-bootstrap"
                                                            validators={['required']}
                                                            errorMessages={['Preenchimento obrigatório']}
                                                        />
                                                        <TextValidator
                                                            label="E-MAIL *"
                                                            onChange={this.handleChange}
                                                            name="email"
                                                            value={this.state.email}
                                                            className="form-ui-bootstrap"
                                                            validators={['required', 'isEmail']}
                                                            errorMessages={['Preenchimento obrigatório', 'E-mail inválido']}
                                                        />
                                                        <InputMask
                                                            mask="(99) 99999-9999"
                                                            value={this.state.telefone}
                                                            className="form-ui-bootstrap"
                                                            label="TELEFONE *"
                                                            name="telefone"
                                                            onChange={this.handleChange}
                                                        >
                                                            {() => <TextField
                                                            label="TELEFONE *"
                                                            name="telefone"
                                                            type="text"
                                                            className="form-ui-bootstrap"
                                                            />}
                                                        </InputMask>                                                        
                                                        <textarea name="mensagem" placeholder="MENSAGEM" onChange={this.handleChange} ></textarea>
                                                        <button type="submit" className="btn m-btn">ENVIAR MENSAGEM<span className="fa fa-angle-right"></span></button>
                                                        {
                                                            this.state.enviado &&
                                                            <blockquote>
                                                                <p><em>{ this.state.msg_enviado }</em></p>
                                                            </blockquote>
                                                        }
                                                    </ValidatorForm>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Rodape />
            </div>
        )
    }
}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value){
    if(value === 'A'){
        return "Automático";
    }else{
        return "Manual";
    }
}

export default Contato