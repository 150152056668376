import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import *  as content from '../content-site';


class Rodape extends Component {

    render() {
        return (

            <div>
                <div className="b-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-xs-6">
                                <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                    <p>SOBRE NÓS</p>
                                    <div className="b-info__contacts-item">
                                        <em>{ content.texto_sobre_nos }</em>
                                    </div>
                                </address>
                            </div>
                            <div className="col-md-3 col-xs-6">
                                <div className="b-info__latest">
                                    <h3>ATENDIMENTO</h3>
                                    <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                        <div className="b-info__contacts-item">
                                            <em>Seg-Sex : { content.horario_seg_sex }<br />
                                                Sab : { content.horario_sab }<br />
                                                </em>
                                        </div>
                                    </address>
                                </div>
                            </div>
                            <div className="col-md-3 col-xs-6">
                                <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                    <p>FALE CONOSCO</p>
                                    <div className="b-info__contacts-item">
                                    <span className="fa fa-map-marker"></span>
                                        
                                        <em> { content.endereco }<br/>
                                             { content.cidade }</em>
                                    </div>
                                    <div className="b-info__contacts-item">
                                        <span className="fa fa-phone"></span>
                                        <em>Telefone:  <a href={ "tel:" + content.telefone_link } >{ content.telefone }</a></em>
                                    </div>
                                    <div className="b-info__contacts-item">
                                        <span className="fa fa-whatsapp"></span>
                                        <em>Whatsapp:  <a href={ content.whatsapp_link }>{ content.whatsapp }</a></em>
                                       
                                    </div>
                                    <div className="b-info__contacts-item">
                                        <span className="fa fa-envelope"></span>
                                        <em>E-mail:  <a href={ "mailto:" + content.email }>{ content.email }</a></em>
                                    </div>
                                </address>
                            </div>
                            <div className="col-md-3 col-xs-6">
                                <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                    <p>VENHA NOS VISITAR</p>
                                    <a target="_blank" href={ content.mapa_link } className="wow slideInLeft" rel="noopener noreferrer" >
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15334.117424651418!2d-57.6799965!3d-16.0898946!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x939affa762a1dfeb%3A0xe961d689a961ee62!2sPirelli%20Ve%C3%ADculos%20-%20C%C3%A1ceres%20-%20MT!5e0!3m2!1spt-BR!2sbr!4v1688471780582!5m2!1spt-BR!2sbr" width="200" height="200" ></iframe>
                                    </a>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="b-footer">
                    {/* <a id="to-top" ref="#TopoSite"><i className="fa fa-chevron-up"></i></a> */}
                    {/* <Link to="/#TopoSite" id="to-top" > <i className="fa fa-chevron-up"></i></Link> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-4">
                                <div className="b-footer__company wow fadeInLeft" data-wow-delay="0.3s">
                                    <div className="">
                                        <img src="/img/logo_bottom.png" alt={ "Logotipo " + content.nome_revenda }  />
                                    </div>
                                    <p>&copy; 2019 Todos os direitos reservados. { content.nome_revenda }.</p>
                                </div>
                            </div>
                            <div className="col-xs-8">
                                <div className="b-footer__content wow fadeInRight" data-wow-delay="0.3s" style={{ marginRight: "40px" }}>
                                    <div className="b-footer__content-social">
                                        <a href={ content.facebook_link } target="_blank" rel="noopener noreferrer"><span className="fa fa-facebook-square"></span></a>
                                        {/* <a href="#"><span className="fa fa-twitter-square"></span></a>
                                        <a href="#"><span className="fa fa-google-plus-square"></span></a> */}
                                        <a href={ content.instagram_link } target="_blank" rel="noopener noreferrer"><span className="fa fa-instagram" target="_blank"></span></a>
                                        {/* <a href="#"><span className="fa fa-youtube-square"></span></a>
                                        <a href="#"><span className="fa fa-skype"></span></a> */}
                                        <a href={ content.whatsapp_link } target="_blank" rel="noopener noreferrer" className="botao-whatsapp">
                                            <i className="fa fa-whatsapp"></i>
                                        </a>
                                    </div>
                                    <nav className="b-footer__content-nav">
                                        <ul>
                                            <li><Link to="/" > Início</Link></li>
                                            <li><Link to="/venda-seu-veiculo" > Venda seu veículo</Link></li>
                                            <li><Link to="/financiamento" > Financiamento</Link></li>
                                            <li><Link to="/contato" > Contato</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }

}

export default Rodape